import styled from "styled-components";
import { YELLOW_COLOR, PRIMARY_COLOR } from "../Colors";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || `${PRIMARY_COLOR}`};
  color: ${(p) => (p.color ? `${PRIMARY_COLOR}` : "#fff")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: 40px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover {
    color: #fff;
    border: 1px solid ${YELLOW_COLOR};
    background-color: ${YELLOW_COLOR};
    transform: scale(1.1);
  }
`;
