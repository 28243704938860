const routes = [
  {
    path: ["/privacy", "/home/privacy"],
    exact: false,
    component: "Privacy",
  },
  {
    path: ["/terms", "/home/terms"],
    exact: true,
    component: "Terms",
  },
  {
    path: ["/", "/home", '*'],
    exact: true,
    component: "Home",
  },
];

export default routes;
